export const globalAppConfig = {
    MAX_FORM_FIELD_LENGTH: 256,
    MAX_SMS_LENGTH: 160,
    // MAPBOX for Map
    MAP_BOX_ACCESS_TOKEN:
        "pk.eyJ1Ijoibmlja2RyZWlsaW5nIiwiYSI6ImNreWM1bHJ0YTBtMjMyb28xcDUxbTlhdG8ifQ.HC1ZRxJzHaHyun8p5GEFSQ",
    MAP_BOX_UPLOAD_TOKEN:
        "sk.eyJ1Ijoibmlja2RyZWlsaW5nIiwiYSI6ImNsOHVxd3ozNjAwOW8zdW51eGQ5dnI2cHQifQ.9Hjhqx_aJowSEbeLFJTiRg",
    // ArcGIS for Detailed Maps
    ARC_GIS_API_KEY:
        "AAPK8a2ae83492dd4742896f9a09a74bc9b5TslK1s_z7YOxLFk4dZ8trn5kqrER5WXHnJGUVLWhAp1UtTAmzOImOSsN1mEsSu8T",
    ARC_GIS_IMAGERY_URL:
        "https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/arcgis/imagery?token=AAPK8a2ae83492dd4742896f9a09a74bc9b5TslK1s_z7YOxLFk4dZ8trn5kqrER5WXHnJGUVLWhAp1UtTAmzOImOSsN1mEsSu8T",
    OWM_API_KEY: "baf132f80132ce6040e67768c7e01576",
    MIXPANEL_API_TOKEN_DEV: "7a07808e45fe961dadd67fa639567835",
    MIXPANEL_API_TOKEN_PROD: "de464b6e35563d97d3d203cc084f1883",
    FINGERPRINTJS_API_KEY: "c0lQB4i0VLCPfYbKhVUC",
    NOTIFICATION_DURATION: 4.5,
    publishKey: "pub-c-f05b8fdd-4645-45e6-821c-c252be09e0d0",
    subscribeKey: "sub-c-2cace7a2-87d1-11ec-9f2b-a2cedba671e8",
    uuid: "plot-app-test-uuid",
    MUI_X_LICENSE_KEY:
        "f0f728fb2119bd4ec3e629ed40f0724aTz05MzMzNyxFPTE3NTExNDQ5MzUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",

    //Anonymous User Limits Before Sign In
    PROJECT_SEARCH_LIMIT: 7,
    PROJECT_SEARCH_RESULTS_LIMIT: 70,
    TEAM_SEARCH_LIMIT: 5,
    TEAM_SEARCH_RESULTS_LIMIT: 50,

    ACCEPTED_UPLOAD_FILE_TYPES:
        "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.zip, .tiff, .tif, .xml, video/*,audio/*",
};

import { LicenseInfo } from "@mui/x-license";
LicenseInfo.setLicenseKey(globalAppConfig.MUI_X_LICENSE_KEY);
